export default class Unit {
  id?: string;
  name = "";
  shortname = "";

  constructor(unit?: Unit) {
    if (unit === undefined || unit === null) {
      return;
    }
    this.id = unit.id;
    this.name = unit.name;
    this.shortname = unit.shortname;
  }
}
