import Unit from "./Unit";
import DisciplineCategory from "./DisciplineCategory";
import JudgeType from "./JudgeType";

export default class Discipline {
  id?: string;
  name = "";
  unit: Unit = new Unit();
  disciplineCategory: DisciplineCategory = new DisciplineCategory();
  judgeType: JudgeType = new JudgeType();
  highestValueIsTheBest = true;
  maximumAValue = 10;
  minimumAValue = 0;
  maximumBValue = 10;
  repetition = 0;
  hasBPoints = false;
  hasPenaltyPoints = false;
  hasAgePoints = false;
  multiplyAWithBPoints = false;
  hasBValueDivisor = false;
  bValueDivisor = 1;

  constructor(discipline?: Discipline) {
    if (discipline === undefined || discipline === null) {
      return;
    }
    this.id = discipline.id;
    this.name = discipline.name;
    this.unit = discipline.unit;
    this.disciplineCategory = discipline.disciplineCategory;
    this.judgeType = discipline.judgeType;
    if (discipline.highestValueIsTheBest) {
      this.highestValueIsTheBest = true;
    } else {
      this.highestValueIsTheBest = false;
    }

    this.maximumAValue = discipline.maximumAValue;
    this.minimumAValue = discipline.minimumAValue;
    this.maximumBValue = discipline.maximumBValue;
    this.repetition = discipline.repetition;

    if (discipline.hasBPoints) {
      this.hasBPoints = discipline.hasBPoints;
    }
    if (discipline.hasPenaltyPoints) {
      this.hasPenaltyPoints = discipline.hasPenaltyPoints;
    }
    if (discipline.hasAgePoints) {
      this.hasAgePoints = discipline.hasAgePoints;
    }

    if (discipline.multiplyAWithBPoints) {
      this.multiplyAWithBPoints = discipline.multiplyAWithBPoints;
    }
    if (discipline.hasBValueDivisor) {
      this.hasBValueDivisor = discipline.hasBValueDivisor;
    }

    this.bValueDivisor = discipline.bValueDivisor;
  }
}
