import HttpService from "../HttpService";
import CalculationFunction from "@/models/sporteventCalculation/CalculationFunction";
import BaseService from "../BaseService";

class CalculationFunctionService extends BaseService<CalculationFunction> {
  public url: string;

  constructor(url = "sportevent-calculation/calculationfunction/") {
    super(url, "");
    this.url = url;
  }

  async getAllByDisciplineId(id: string) {
    const response = await HttpService.get<CalculationFunction[]>(
      `${this.url}bydiscipline/${id}/`
    );
    return response;
  }

  async uploadCsv(formData: FormData) {
    // eslint-disable-next-line
    const response = await HttpService.post<Record<string, any>>(
      "sportevent-calculation/calculationfunction-import",
      formData,
      true
    );
    return response;
  }
}

export default new CalculationFunctionService();
