
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import CalculationFunction from "../../models/sporteventCalculation/CalculationFunction";
import AgeGenderGroup from "../../models/sporteventCalculation/AgeGenderGroup";
import CalculationFunctionService from "../../services/sporteventCalculation/CalculationFunctionService";
import AgeGenderGroupService from "../../services/sporteventCalculation/AgeGenderGroupService";
import DisciplineService from "../../services/sporteventCalculation/DisciplineService";
import Discipline from "../../models/sporteventCalculation/Discipline";

@Component({
  components: {
    Multiselect,
  },
})
export default class CalculationFunctionComponent extends Vue {
  public calculationFunction: CalculationFunction = new CalculationFunction();
  public allageGenderGroups: AgeGenderGroup[] = [];
  public discipline: Discipline = new Discipline();

  async mounted(): Promise<void> {
    this.discipline = new Discipline(
      await DisciplineService.get(this.$route.params.discipline_id)
    );

    AgeGenderGroupService.getAll().then((items: AgeGenderGroup[]) => {
      this.allageGenderGroups = items;
    });
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      CalculationFunctionService.get(this.$route.params.id).then(
        (item: CalculationFunction) => {
          this.calculationFunction = item;
        }
      );
    } else {
      this.calculationFunction.discipline = this.discipline;
    }
  }

  save(): void {
    //console.log(this.calculationFunction);
    CalculationFunctionService.save(this.calculationFunction).then(
      (item: CalculationFunction) => {
        //console.log(item);
        ToastService.Success(i18n.tc("labels.saved"), item.name);
        if (item) {
          this.calculationFunction = item;
          this.handleBack();
        }
      }
    );
  }

  handleBack(): void {
    this.$router.back();
  }

  getSelectName(ageGenderGroup: AgeGenderGroup): string {
    ageGenderGroup = new AgeGenderGroup(ageGenderGroup);
    return ageGenderGroup.getName();
  }
}
