export default class DisciplineCategory {
  id?: string;
  name = "";

  constructor(disciplineCategory?: DisciplineCategory) {
    if (disciplineCategory === undefined || disciplineCategory === null) {
      return;
    }
    this.id = disciplineCategory.id;
    this.name = disciplineCategory.name;
  }
}
