import AgeGenderGroup from "@/models/sporteventCalculation/AgeGenderGroup";
import BaseService from "../BaseService";

class AgeGenderGroupService extends BaseService<AgeGenderGroup> {
  public url: string;

  constructor(url = "sportevent-calculation/agegendergroup/") {
    super(url, "");
    this.url = url;
  }
}

export default new AgeGenderGroupService();
