export default class JudgeType {
  id?: string;
  name = "";
  shortname = "";

  constructor(judgeType?: JudgeType) {
    if (judgeType === undefined || judgeType === null) {
      return;
    }
    this.id = judgeType.id;
    this.name = judgeType.name;
    this.shortname = judgeType.shortname;
  }
}
