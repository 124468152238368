import Discipline from "@/models/sporteventCalculation/Discipline";
import DisciplineCategory from "@/models/sporteventCalculation/DisciplineCategory";
import BaseService from "../BaseService";
import HttpService from "../HttpService";

class DisciplineService extends BaseService<Discipline> {
  constructor() {
    super("sportevent-calculation/discipline/", "localstore_persons");
  }

  async uploadCsv(formData: FormData) {
    // eslint-disable-next-line
    const response = await HttpService.post<Record<string, any>>(
      "sportevent-calculation/discipline-import",
      formData,
      true
    );
    return response;
  }


  async getDisciplineCategories(disciplines: Discipline[]): Promise<DisciplineCategory[]> {
    let disciplineCategories: DisciplineCategory[] = [];
    disciplines.forEach( (discipline : Discipline) =>{
      let index = disciplineCategories.findIndex(d => d.id === discipline.disciplineCategory.id)
      if(index === -1){
        disciplineCategories.push(discipline.disciplineCategory);
      }
    });
    return disciplineCategories;
  }
}
export default new DisciplineService();
