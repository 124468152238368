import AgeGenderGroup from "./AgeGenderGroup";
import Discipline from "./Discipline";

export default class CalculationFunction {
  id?: string;
  name = "";
  ageGenderGroup: AgeGenderGroup = new AgeGenderGroup();
  discipline: Discipline = new Discipline();
  point0 = 0;
  value0 = 0;
  point1 = 0;
  value1 = 0;
  point2 = 0;
  value2 = 0;
  point3 = 0;
  value3 = 0;
  point4 = 0;
  value4 = 0;
  point5 = 0;
  value5 = 0;
  point6 = 0;
  value6 = 0;
  point7 = 0;
  value7 = 0;
  point8 = 0;
  value8 = 0;
  point9 = 0;
  value9 = 0;

  constructor(calculationFunction?: CalculationFunction) {
    if (calculationFunction === undefined || calculationFunction === null) {
      return;
    }
    this.id = calculationFunction.id;
    this.name = calculationFunction.name;
    this.ageGenderGroup = calculationFunction.ageGenderGroup;
    this.point0 = calculationFunction.point0;
    this.value0 = calculationFunction.value0;
    this.point1 = calculationFunction.point1;
    this.value1 = calculationFunction.value1;
    this.point2 = calculationFunction.point2;
    this.value2 = calculationFunction.value2;
    this.point3 = calculationFunction.point3;
    this.value3 = calculationFunction.value3;
    this.point4 = calculationFunction.point4;
    this.value4 = calculationFunction.value4;
    this.point5 = calculationFunction.point5;
    this.value5 = calculationFunction.value5;
    this.point6 = calculationFunction.point6;
    this.value6 = calculationFunction.value6;
    this.point7 = calculationFunction.point7;
    this.value7 = calculationFunction.value7;
    this.point8 = calculationFunction.point8;
    this.value8 = calculationFunction.value8;
    this.point9 = calculationFunction.point9;
    this.value9 = calculationFunction.value9;
  }
}
